import React, { Component } from 'react';
const URL = require('url-parse');

export class SafeURL extends Component {
  isSafe(dangerousURL, text) {
    const url = URL(dangerousURL, {});
    if (url.protocol === 'http:') return true;
    if (url.protocol === 'https:') return true;
    return false;
  }
  render() {
    const dangerousURL = this.props.dangerousURL;
    const safeURL = this.isSafe ? dangerousURL : null;
    return (
      <a
        className={this.props.className}
        href={safeURL}
        onClick={this.props.onClick}
        target={this.props.newTab ? '_blank' : ''}
        rel={this.props.newTab ? 'noopener noreferrer' : ''}
        style={this.props.style}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
      >
        {this.props.text}
      </a>
    );
  }
}
