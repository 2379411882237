import styled from 'styled-components'
import { Link } from 'gatsby'

export const Div = styled.div`
  padding: ${({ padding }) => (padding && padding)};
  padding-top: ${({ padding_top }) => (padding_top && padding_top)};
  padding-bottom: ${({ padding_bottom }) => (padding_bottom && padding_bottom)};
  padding-left: ${({ padding_left }) => (padding_left && padding_left)};
  padding-right: ${({ padding_right }) => (padding_right && padding_right)};
  margin: ${({ margin }) => (margin && margin)};
  margin-top: ${({ margin_top }) => (margin_top && margin_top)};
  margin-bottom: ${({ margin_bottom }) => (margin_bottom && margin_bottom)};
  margin-left: ${({ margin_left }) => (margin_left && margin_left)};
  margin-right: ${({ margin_right }) => (margin_right && margin_right)};
  width: ${({ width }) => (width && width)};
  max-width: ${({ maxWidth }) => (maxWidth && maxWidth)};
  height: ${({ height }) => (height && height)};
  display: ${({ display }) => (display && display)};
  flex-direction: ${({ flexDirection }) => (flexDirection && flexDirection)};
  align-items: ${({ align_items }) => (align_items && align_items)};
  justify-content: ${({ justify_content }) => (justify_content && justify_content)};
  position: ${({ position }) => (position && position)};
  top: ${({ top }) => (top && top)};
  right: ${({ right }) => (right && right)};
  bottom: ${({ bottom }) => (bottom && bottom)};
  left: ${({ left }) => (left && left)};
  z-index: ${({ zIndex }) => (zIndex && zIndex)};
  background-color: ${({ background_color }) => (background_color && background_color)};
  @media (max-width: 1030px) {
    margin: ${({ margin1030 }) => (margin1030 && margin1030)};
    height: ${({ height1030 }) => (height1030 && height1030)};
    flex-direction: ${({ flexDirection1030 }) => (flexDirection1030 && flexDirection1030)};
  }
  @media (max-width: 960px) {
    padding: ${({ padding960 }) => (padding960 && padding960)};
    margin: ${({ margin960 }) => (margin960 && margin960)};
    width: ${({ width960 }) => (width960 && width960)};
    height: ${({ height960 }) => (height960 && height960)};
    flex-direction: ${({ flexDirection960 }) => (flexDirection960 && flexDirection960)};
  }
  @media (max-width: 870px) {
    display: ${({ display870 }) => (display870 && display870)};
  }
`;

export const StyledLink = styled(Link)`
  width: ${({ width }) => (width && width)};
  height: ${({ height }) => (height && height)};
  display: ${({ display }) => (display && display)};
  align-items: ${({ align_items }) => (align_items && align_items)};
  justify-content: ${({ justify_content }) => (justify_content && justify_content)};
  color: ${({ color }) => (color && color)};
  text-decoration: ${({ textDecoration }) => (textDecoration && textDecoration)};
  background-color: ${({ background_color }) => (background_color && background_color)};
  cursor: pointer;
`;

export const Button = styled.button`
  padding: ${({ padding }) => (padding && padding)};
  margin: ${({ margin }) => (margin && margin)};
  width: ${({ width }) => (width && width)};
  height: ${({ height }) => (height && height)};
  display: ${({ display }) => (display && display)};
  position: ${({ position }) => (position && position)};
  top: ${({ top }) => (top && top)};
  right: ${({ right }) => (right && right)};
  bottom: ${({ bottom }) => (bottom && bottom)};
  left: ${({ left }) => (left && left)};
  z-index: ${({ zIndex }) => (zIndex && zIndex)};
  opacity: ${({ opacity }) => (opacity && opacity)};
  background: ${({ background }) => (background ? background : 'transparent')};
  transition: ${({ transition }) => (transition && transition)};
  border: ${({ border }) => (border ? border : 'none')};
  outline: none;
  cursor: pointer;
  &:hover {
    opacity: ${({ opacityHover }) => (opacityHover && opacityHover)};;
  }
  @media (max-width: 960px) {
    margin: ${({ margin960 }) => (margin960 && margin960)};
    width: ${({ width960 }) => (width960 && width960)};
    height: ${({ height960 }) => (height960 && height960)};
    display: ${({ display960 }) => (display960 && display960)};
  }
`;

export const H2 = styled.h2`
  margin: ${({ margin }) => (margin && margin)};
  font-size: ${({ fontSize }) => (fontSize && fontSize)};
  font-weight: ${({ fontWeight }) => (fontWeight && fontWeight)};
  text-align: ${({ textAlign }) => (textAlign && textAlign)};
  color: ${({ color }) => (color && color)};
  position: ${({ position }) => (position && position)};
  @media (max-width: 960px) {
    font-size: ${({ fontSize960 }) => (fontSize960 && fontSize960)};
  }
`;

export const P = styled.p`
  margin: ${({ margin }) => (margin && margin)};
  width: ${({ width }) => (width && width)};
  max-width: ${({ maxWidth }) => (maxWidth && maxWidth)};
  font-size: ${({ fontSize }) => (fontSize && fontSize)};
  font-weight: ${({ fontWeight }) => (fontWeight && fontWeight)};
  text-align: ${({ textAlign }) => (textAlign && textAlign)};
  color: ${({ color }) => (color && color)};
  opacity: ${({ opacity }) => (opacity && opacity)};
  @media (max-width: 960px) {
    font-size: ${({ fontSize960 }) => (fontSize960 && fontSize960)};
  }
`;

export const Span = styled.span`
  font-size: ${({ fontSize }) => (fontSize && fontSize)};
  font-weight: ${({ fontWeight }) => (fontWeight && fontWeight)};
  color: ${({ color }) => (color && color)};
`;

export const A = styled.a`
  margin: ${({ margin }) => (margin && margin)};
  width: ${({ width }) => (width && width)};
  height: ${({ height }) => (height && height)};
  display: ${({ display }) => (display && display)};
  align-items: ${({ align_items }) => (align_items && align_items)};
  justify-content: ${({ justify_content }) => (justify_content && justify_content)};
  background: ${({ background }) => (background && background)};
  color: ${({ color }) => (color && color)};
  text-decoration: none;
  cursor: pointer;
`;