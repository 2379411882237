import React, { useState } from "react"
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Div, Button, P, A } from "./styled-components"
import { linkedin, phone, routes } from './config'
import Logo from "../assets/logo.svg"
import Linkedin from "../assets/linkedin.svg"
import Phone from "../assets/phone.svg"
import BtnMenu from "../assets/btnMenu.svg"

const Nav = styled.nav`
  height: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 960px) {
    height: 60%;
    flex-direction: column;
    position: absolute;
    top: 101px;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: #fff;
    display: ${({ show }) => (show ? `flex` : 'none')};
  }
`;

const StyledLink = styled(Link)`
  margin: 0 20px 0 0;
  height: 100%;
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: #69B02A;
  }
  @media (max-width: 960px) {
    margin: 0;
    width: 100%;
    border-bottom: solid 1px #23227B;
  }
`;

const Header = () => {
  const [show, setShow] = useState(false)

  const btnMenu = (event) => {
    event.preventDefault()
    setShow(!show)
  }

  return (
    <header
      style={{
        width: "100%",
        height: "100px",
        background: "#fff",
      }}
    >
      <Div
        padding="0 10px"
        margin="0 auto"
        maxWidth="1200px"
        height="100%"
        display="flex"
        justify_content="space-between"
      >
        <Link
          to="/"
          style={{
            alignItems: "center",
            display: "flex"
          }}
        >
          <Div
            width="80px"
            height="80px"
            width960="65px"
          >
          <Logo
            alt="logo"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
          </Div>
        </Link>
        <Div
          height="100%"
          display="flex"
        >
          <Nav
            show={show}
          >
            {routes.map((route, i) =>
              <StyledLink
                key={i}
                to={route.path}
                getProps={({ isCurrent }) => {
                  // the object returned here is passed to the
                  // anchor element's props
                  return {
                    style: {
                      color: isCurrent && "#69B02A",
                    }
                  }
                }}
              >
                {route.name}
              </StyledLink>
            )}
          </Nav>
          <Div
            width="135px"
            height="100%"
            display="flex"
            align_items="center"
            justify_content="center"
            width960="132px"
          >
            <A
              width="23px"
              height="23px"
              rel="noreferrer"
              target="_blank"
              href={linkedin}
            >
              <Linkedin alt="icon-linkedin" />
            </A>
            <A
              margin="0 10px 0 0"
              width="23px"
              height="23px"
              rel="noreferrer"
              href={`tel:${phone}`}
            >
              <Phone alt="icon-fb" />
            </A>
            <P
              margin="0"
              fontSize="12px"
              fontWeight="bold"
              fontSize960="10px"
            >
              Contact Us <br/> {phone}
            </P>
          </Div>
          <Button
            margin="0 0 0 5px"
            display="none"
            display960="inline"
            onClick={btnMenu}
          >
            <BtnMenu alt="icon-menu" />
          </Button>
        </Div>
      </Div>
    </header>
  )
}

export default Header
