import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata.title
  const keywordsFormated = site.siteMetadata.keywords.join(",")
  const imagePreview = "https://firebasestorage.googleapis.com/v0/b/pes-tab.appspot.com/o/pes-logo-white-bg.jpg?alt=media&token=6e13bdf8-2117-436a-8cb2-98c13fac3be4"

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={'PREMIER ENERGY SOLUTIONS, INC'}
      titleTemplate={defaultTitle ? defaultTitle : null}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: "keywords",
          content: keywordsFormated,
        },
        {
          property: `og:title`,
          content: 'PREMIER ENERGY SOLUTIONS, INC',
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `https://pes-tab.com/`,
        },
        {
          property: `og:image:url`,
          content: imagePreview,
        },
        {
          property: `og:image`,
          content: imagePreview,
        },
        {
          property: `og:image:type`,
          content: `image/png`,
        },
        {
          property: `og:image:width`,
          content: `200`,
        },
        {
          property: `og:image:height`,
          content: `200`,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
