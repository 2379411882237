import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Div, P, A } from './styled-components';
import { emailFooter, linkedin, routes, phone } from './config';
import Logo from '../assets/logo.svg';
import Linkedin from '../assets/linkedin.svg';

const Nav = styled.nav`
  display: flex;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const StyledLink = styled(Link)`
  margin: 10px 0 0;
  font-size: 18px;
  color: #23227b;
  text-decoration: none;
  cursor: pointer;
`;

const Footer = () => (
  <footer
    style={{
      padding: '50px 20px 30px 20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    <Nav>
      <Div width='200px' width960='250px'>
        <Link to='/'>
          <Logo
            alt='logo'
            style={{
              width: '100px',
              height: '100px',
            }}
          />
        </Link>
        <P margin='10px 0 0 0' fontSize='18px' color='#23227B'>
          Email: {emailFooter}
        </P>
        <P margin='10px 0 0 0' fontSize='18px' color='#23227B'>
          Phone: {phone}
        </P>
      </Div>
      <Div
        margin='0 160px'
        width='110px'
        display='flex'
        flexDirection='column'
        margin960='40px 0'
        width960='200px'
      >
        <P margin='0' fontSize='22px' fontWeight='bold' color='#23227B'>
          Menú
        </P>
        {routes.map((route, i) => (
          <StyledLink key={i} to={route.path}>
            {route.name}
          </StyledLink>
        ))}
      </Div>
      <Div width='140px' width960='200px'>
        <P margin='0' fontSize='22px' fontWeight='bold' color='#23227B'>
          Social Media
        </P>
        <A
          margin='15px 0'
          width='100px'
          display='flex'
          rel='noreferrer'
          href={linkedin}
          target='_blank'
        >
          <Linkedin alt='icon-linkedin' />
          <P margin='0 0 0 5px' fontSize='18px' color='#23227B'>
            Linkedin
          </P>
        </A>
      </Div>
    </Nav>
    <P
      margin='30px 0 0 0'
      fontSize='20px'
      textAlign='center'
      color='#23227B'
      fontSize960='16px'
    >
      © {new Date().getFullYear()} Premier Energy INC ®️ All Rights Reserved.{' '}
      {/*Terms of Use Privacy Policy*/}
    </P>
  </footer>
);

export default Footer;
