// // Premier Energy, Inc (Naples)
// export const addressEastOffice1 = "3330 Fairchild Gardens Ave"
// export const addressEastOffice2 = "Unit 31062 Palm Beach Gardens, FL  33410"
// export const phoneEastOffice1 = "443-458-8012"
// export const phoneEastOffice2 = "239-822-3640"
// export const emailEastOffice = "jbryan@pes-tab.com"

// // Premier Energy, Inc (West Office)
// export const addressWestOffice1 = "4110 Enterprise Ave"
// export const addressWestOffice2 = "Suite, 214 Naples, FL 34104"
// export const phoneWestOffice = "239-822-3640"
// export const emailWestOffice = "alex@pes-tab.com"

export const addressOffice1 = {
  officeName: 'Naples Office',
  PO: 'P.O Box 7606',
  address: 'Naples, FL 34101',
  phoneNumber: '(239) 822-3640',
  email: 'alex@pes-tab.com',
}

export const addressOffice2 = {
  officeName: 'Tampa Office',
  PO: 'P.O Box 89984',
  address: 'Tampa, FL 33689',
  phoneNumber: '(239) 822-3640',
  email: 'alex@pes-tab.com',
}
export const addressOffice3 = {
  officeName: 'DMV Office',
  PO: 'P.O Box 220703',
  address: 'Chantilly, VA 20153',
  phoneNumber: '(239) 822-3640',
  email: 'alex@pes-tab.com',
}

// Enterprise
export const linkedin = "https://www.linkedin.com/in/alex-rendon-9412a691/"
export const phone = "239-822-3640"
export const emailFooter = "alex@pes-tab.com"

// General Routes
export const routes = [
  {
    name: "Home",
    path: "/"
  },
  {
    name: "About Us",
    path: "/about-us"
  },
  {
    name: "Services",
    path: "/services"
  },
  {
    name: "Reports",
    path: "/reports"
  },
  {
    name: "Certifications",
    path: "/certifications"
  },
  {
    name: "Projects",
    path: "/projects"
  },
  {
    name: "Staff",
    path: "/staff"
  },
  {
    name: "Contact",
    path: "/contact"
  }
]