import React from 'react';
import { SafeURL } from '../utils/safe-url';
import Neurons from '../images/Neurons.svg';
import NeuronsHover from '../images/NeuronsHover.svg';

class PartnerBanner extends React.Component {
  state = {
    hover: false,
  };

  onHover = () => {
    this.setState({ hover: true });
  };

  onLeave = () => {
    this.setState({ hover: false });
  };

  render() {
    return (
      <div
        className='PartnerBanner__Container'
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '30px',
          padding: '10px'
        }}
      >
        <p style={{ margin: '0', color: '#23227b', marginRight: '10px', fontSize: '12px' }}>MADE BY</p>
        <SafeURL
          dangerousURL='https://neurons.agency'
          className='PartnerBanner__Url'
          newTab={true}
          style={{
            width: '50px',
            height: '18px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0',
            padding: '0',
          }}
          onMouseEnter={() => this.onHover()}
          onMouseLeave={() => this.onLeave()}
          text={
            <img
              src={this.state.hover ? NeuronsHover : Neurons}
              alt='neurons'
              style={{ width: 'inherit', margin: '0' }}
            />
          }
        />
      </div>
    );
  }
}

export default PartnerBanner;
